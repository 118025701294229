




































































import Navbar from "@/components/Core/Navbar.vue";
import Loading from "@/components/Web/Loading.vue";
import { User } from "@/store/user";
import { Auth } from "@/store/auth";
import { Core } from "@/store/core";
import { Web } from "@/store/web";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
    components: {
        Navbar,
        Loading,
    },
    computed: {}
})

export default class UserClass extends Vue {
    
    drawer: any = true
    group: any = null

    private userDialog: boolean = false

    private form: any = {
        in_up : false
    }
    private studentUp:boolean = false;
    private agencyType: any = []
    private chooseAgencyType: number | null = null
    private agencies: any = []

    async created() {

        await Auth.checkToken();
        await User.loadUser();

        // if(User.routeUser == '/' || User.routeUser == '/admin/'){
        //   await this.$router.replace('/user' )
        // }
        let user = await User.getUser();

        if (!user.register_type) {
            
            await Web.switchLoad(false);
            this.form.user = user.pk

            this.form.register_type = ((user.username).split("@__@"))[1]
            console.log(((user.email).split("@"))[1])
            let in_up = ((user.email).split("@"))[1]
            if (in_up === "up.ac.th") {
                let student =  await this.checkType(user.username)

                this.form.in_up = true
              if(student){
                this.form.in_up = false
                this.studentUp = true
              }
            }

            if (!this.form.register_type) {
                this.form.register_type = "ปกติ"
            }

          let typeAg = await Core.getHttp(`/api/ita/v1/agencytype/`)
          if(this.form.in_up && !this.studentUp){
            typeAg.pop();
            this.agencyType = typeAg
          }else{
            this.agencyType = typeAg[2]
          }

          this.userDialog = true
            
        }

    }
    async saveProfile() { 
         // this.form.passing = true;
         // this.form.oit = true;
        let profile = await Core.postHttp(`/api/ita/v1/profile/`, this.form)
        if (profile.id) {
            await location.reload()
        }

    }
    @Watch('chooseAgencyType')
    private async chnageChooseAgencyType(val: number) {
        this.agencies = await Core.getHttp(`/api/ita/v2/agencys/?agency_type=${val}`)
    }

    @Watch('form.in_up')
    private async chnageAgency(val: boolean) {
      this.agencies = []
      let typeAg = await Core.getHttp(`/api/ita/v1/agencytype/`)
        if(val){
          typeAg.pop();
          this.agencyType = typeAg
        }else{
          this.agencyType = typeAg[2]
        }
    }

     async logout(){
    let user = await User.getUser();
    console.log(user)
    try {
      await Auth.logout();
    } catch (error) {
      
    }
     window.open('https://login.microsoftonline.com/logout.srf', '_blank');
    await this.$router.replace('/')
    await location.reload()
  }

  async checkType(email:any) {
    let cutMail = email.split('@');
    let mail = Number(cutMail[0]);
    if (isNaN(mail)) {
      return false
    } else {
      return true
    }
  }



}
